import React, { Component, useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IMaskInput } from "react-imask";
import { withRouter, Link } from "react-router-dom";
import { cnpjMask, cnpjValidation, onlyNumber } from "../../utils/mask"
import { DateTime } from 'luxon';
import { addNf, updateNf, getNf } from "./NfActions";
import { getMovements, editMovements } from "components/movement/MovementActions"
import Loader from "components/utils/Loader"
import { putBlankProperties, blobToBase64, convertB64ToBlob, fixBlankProperties } from "../../utils/Utils";
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Tooltip } from 'primereact/tooltip';
import { Column } from 'primereact/column';
import { header } from "../../utils/Datagrid";
import { datetimeOrDateFromIso, datetimeFromIso } from "../../utils/Data";
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import StockedProductModal from "components/stock/StockedProductModal";
import ControlKilosLiters from "components/utils/ControlKilosLiters";
import { PencilFill, TrashFill, Check2Circle} from 'react-bootstrap-icons';

// react-bootstrap components
import {
    Badge,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    InputGroup,
    Row,
    Col,
    Modal,
    Table,
    Accordion
} from "react-bootstrap";

class AddNf extends Component {
    constructor(props, context) {
        super(props, context);
        this.dt = React.createRef();
        this.toast = React.createRef();
        let query = new URLSearchParams(this.props.location.search);
        this.state = {
            mv: { search: '0' },
            filters: {
                number: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                issued_at: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
                enabled_virtual: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
            },
            // nf
            obj: {
                id: "",
                number: "",
                issued_at: "",
                enabled: true ,
                updated: false
            },
            search: {
                nf_id: "",
                start_at: "",
                end_at: ""
            },
            sps: [{
                ncmsynonyms: { options: [] },
                pr: {
                    name: "",
                    ncm_general: "",
                    commercial_name: "",
                    concentration: "",
                    density: "",
                    supplier: "",
                    enabled: true
                },
                sp: {
                    product: "",
                    quantity: "",
                    liters: "",
                    kilos: "",
                    isLiters: "",
                    enabled: true
                }
            }],
            out_nfs: [{
                obj: {
                    id: "",
                    number: "",
                    issued_at: "",
                    enabled: true
                },
                sps: [{
                    ncmsynonyms: { options: [] },
                    pr: {
                        name: "",
                        ncm_general: "",
                        commercial_name: "",
                        concentration: "",
                        density: "",
                        supplier: "",
                        enabled: true
                    },
                    sp: {
                        product: "",
                        quantity: "",
                        liters: "",
                        kilos: "",
                        isLiters: "",
                        enabled: true
                    }
                }],
            }],
            movements: [{
                mv: {
                    id: "",
                    movement_type: "",
                    movement_type_description: "",
                    is_in: "",
                    movement_subtype: "",
                    liters: "",
                    kilos: "",
                    isLiters: "",
                    product: {
                        id: "",
                        density: "",
                        concentration: "",
                        commercial_name: "",
                        ncm_general_name: "",
                        ncm_general_code: "",
                    }
                }
            }
            ],
            products: [],
            movementsEdited: [],
            expandedRows: [],
            validated: false,
            loading: false,
            show: false,
            previousPage: query.get('action') === '1' ? '/manage/movements' : '/manage/nfs'

        }
    }

    cols = [
        { field: 'ncm_general_code', header: 'NCM' },
        { field: 'product_name', header: 'Produto' },
        { field: 'concentration', header: 'Concentração' },
        { field: 'density', header: 'Densidade' },
        { field: 'enabled_virtual', header: 'Ativo' }
    ];

    exportColumns = this.cols.map((col) => ({ title: col.header, dataKey: col.field }));

    setValidated = (v) => {
        this.setState({ validated: v });
    }

    handleSubmit = (event) => {
        const form = document.getElementById("AddNf")
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        this.setValidated(true);
        if (form.checkValidity() !== false) {

            this.onSaveClick();
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.nfsReduc.nf.id !== prevState.obj.id) {
            const { nf } = nextProps.nfsReduc;
            const { movements } = nextProps.movementsReduc;
            nf.number = nf.number?.toString() || ""
            var obj = putBlankProperties(nf)
            obj.movements = movements
            return {
                obj: nf,
                validated: prevState.validated
            };
        }
        else
            return null
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id != null) {
            this.setState({ loading: true })
            this.props.getNf(id).then(() => {
                const nf = this.props.nfsReduc.nf
                let query = new URLSearchParams(this.props.location.search);
                let start_at = query.get('start_at')
                let end_at = query.get('end_at')
                if (!start_at && !end_at) {
                    start_at = nf.issued_at
                    end_at = nf.issued_at
                }
                this.changeQueryParameter(start_at, end_at)
                this.getMovements({ nf: id, order_by: 'reference_date', start_at: start_at, end_at: end_at }).then((movements) => {
                    if (movements.length == 0){
                        this.setState({ loading: true })
                        this.getMovements({ nf: id, order_by: 'reference_date', start_at: start_at })
                    }
                })
            })
            .catch(error => { this.setState({ loading: false }) });
        }
        else {
            this.props.nfsReduc.nf = this.state.obj
        }
    }

    onChangeCheckbox = e => {
        var obj = { ...this.state.obj }
        obj[e.target.name] = e.target.value === 'true' ? false : true
        this.setState({ co });
    }

    onChange = e => {
        var obj = { ...this.state.obj }
        if (obj[e.target.name] !== e.target.value){
            obj['updated'] = true
        }
        obj[e.target.name] = e.target.value
        this.setState({ obj });
    };

    changeQueryParameter(start_at, end_at) {
        let queryParams = `?start_at=${start_at}`;
        if (end_at){
            queryParams += `&end_at=${end_at}`;
        }
        this.props.history.push(`${this.props.location.pathname}${queryParams}`);
    }

    getMovements = (options) => {
        return this.props.getMovements(options).then(() => {
            const { movements } = this.props.movementsReduc;
            var products = []
            movements.map(obj => {
                var mov = { ...obj }
                var prod = { ...obj.product }
                delete mov.product
                delete mov.commercial_product

                mov.quantity_kilos = obj.quantity_kilos.replace('.', ',')
                mov.quantity_liters = obj.quantity_liters.replace('.', ',')
                mov.reference_date = obj.reference_date
                mov.product = {
                    id: prod.id,
                    density: prod.density,
                    concentration: prod.concentration,
                }
                let density = obj?.product?.density.replace('.', ',')
                mov.mu = obj.mu
                if (!products.find(p => p.id === prod.id)) {
                    prod.product_name = obj?.product?.commercial_name ? obj?.product?.commercial_name : obj?.product?.ncm_general_name
                    prod.enabled_virtual = obj.enabled ? "Sim" : "Não"
                    prod.density = density
                    prod.movements = []
                    prod.movements.push(mov)
                    products.push(prod)
                    return prod
                }
                else {
                    prod = products.find(p => p.id === prod.id)
                    prod.movements.push(mov)
                }
            });
            var search = { ...this.state.search }
            search.nf_id = options.nf
            search.start_at = options.start_at
            if (options.end_at){
                search.end_at = options.end_at
            }
            else{
                search.end_at = ''
            }
            this.changeQueryParameter(search.start_at, search.end_at)
            this.setState({ products: products, loading: false, search: search });
            return movements
        })
        .catch(error => { console.log(error); this.setState({ loading: false }) });
    }

    onChangeSearch = e => {
        var search = { ...this.state.search }
        search[e.target.name] = e.target.value
        this.setState({ search, loading: true });
        this.getMovements({
            nf: search.nf_id, order_by: 'reference_date',
            start_at: search.start_at, end_at: search.end_at
        })
    }

    onChangeNumber = e => {

    };

    onSaveClick = (event) => {
        let mvementsEdited = [...this.state.movementsEdited];
        mvementsEdited = mvementsEdited.map(m => {
            m.quantity_kilos = m.quantity_kilos.replace(',', '.')
            m.quantity_liters = m.quantity_liters.replace(',', '.')
            m.density = m.density.replace(',', '.')
            return m
        })
        
        mvementsEdited = fixBlankProperties(mvementsEdited)
        // if (mvementsEdited.length > 0){
        this.setState({ loading: true })
        let nf = { ...this.state.obj }
        nf = fixBlankProperties(nf)
        nf.issued_at = datetimeFromIso(nf.issued_at)
        this.props.editMovements(mvementsEdited, nf).then(() => {
            this.setState({ loading: false })
            this.props.history.push(this.state.previousPage);
        }
        )
            .catch(error => {
                this.setState({ loading: false })
            }
            )
        // }else{
        //     this.toast.current.show({ severity: 'info', summary: 'Info', detail: 'Nenhuma ação foi alterada', life: 3000 });
        // }
    };

    onRemoveToEdit = (movement) => {
        if (movement){
            this.toast.current.show({ severity: 'info', summary: 'Ação removida da edição', detail: '', life: 3000 });
            let _movementsEdited = [...this.state.movementsEdited];
            _movementsEdited = _movementsEdited.filter(m => m.id !== movement.id);
            this.setState({ movementsEdited: _movementsEdited });
        }else{
            this.toast.current.show({ severity: 'info', summary: 'Ação descartada', detail: '', life: 3000 });
        }
    }

    updateMovementsEdited = (movements) => {
        this.toast.current.show({ severity: 'warn', summary: 'Ações modificadas.', detail: 'Clique em "Salvar" para registrar as suas alterações.', life: 5000 });
        let _newMovements = movements.filter(m => !this.state.movementsEdited.find(mv => mv.id === m.id));
        let _editedMovements = movements.filter(m => this.state.movementsEdited.find(mv => mv.id === m.id));
        let movemenetsEdited = [...this.state.movementsEdited];

        for (let i = 0; i < _editedMovements.length; i++) {
            let index = movemenetsEdited.findIndex(mv => mv.id === _editedMovements[i].id);
            
            let editedMovement = { ...movemenetsEdited[index], ..._editedMovements[i] };
            movemenetsEdited[index] = editedMovement;
        }

        const updatedMovements = [...movemenetsEdited, ..._newMovements];

        let products = [...this.state.products];
        products.forEach(p => {
            updatedMovements.forEach(m => {
                if (p.movements.find(mv => mv.id == m.id)){
                    p.movements[p.movements.findIndex(mv => mv.id == m.id)] = m
                }
            })
        });
        this.setState({ movementsEdited: updatedMovements, products: products });
    };


    setExpandedRows = (rowData) => {
        this.setState({ expandedRows: rowData });
    }

    expandAll = (products) => {
        let _expandedRows = {};

        products.forEach((p) => (_expandedRows[`${p.id}`] = true));

        this.setExpandedRows(_expandedRows);
    };

    collapseAll = () => {
        this.setExpandedRows(null);
    };

    onRowExpand = (event) => {
        this.toast.current.show({ severity: 'info', summary: 'Ações expandidas e prontos para edição', detail: event.data.name, life: 3000 });
    };

    onRowCollapse = (event) => {
        this.toast.current.show({ severity: 'success', summary: 'Ações colapsadas', detail: event.data.name, life: 3000 });
    };

    onRowEditComplete = (e, rowData, product) => {
        let _products = [...this.state.products];
        let _movementsEdited = [...this.state.movementsEdited];
        let { newData, index } = e;

        if (!_movementsEdited.find(m => m.id === newData.id)) {
            if (product.movements.find(m => m.id === newData.id && (m.quantity_liters !== newData.quantity_liters || m.quantity_kilos !== newData.quantity_kilos) || m.reference_date !== newData.reference_date)) {
                // m.quantity_liters !== newData.quantity_liters && m.quantity_kilos !== newData.quantity_kilos
                // _movementsEdited[_movementsEdited.indexOf(newData)] = newData
                _movementsEdited.push(newData)
            }
        } else {
            _movementsEdited[_movementsEdited.findIndex(m => m.id === newData.id)] = newData
        }
        product.movements[index] = newData;
        _products[_products.indexOf(product)] = product;
        this.setState({ products: _products, movementsEdited: _movementsEdited });
    };

    onEditClick = (rowData) => {
        this.toast.current.show({ severity: 'info', summary: 'Ação em edição', detail: rowData.id, life: 3000 });
        let _movementsEdited = [...this.state.movementsEdited];
        if (!_movementsEdited.find(m => m.id === rowData.id)) {
            _movementsEdited.push(rowData)
        }
        else {
            _movementsEdited[_movementsEdited.findIndex(m => m.id === rowData.id)] = rowData
        }
        this.setState({ show: true, movementsEdited: _movementsEdited });
    };
    
    onDeleteClick = (rowData) => {
        this.toast.current.show({ severity: 'warn', summary: 'Ação deletada', detail: rowData.id, life: 3000 });
    };

    onCancelStockProduct = () => {
        this.setState({ show: false });
    };
        

    alocBodyTemplate = (rowData) => {
        return (
            <div className="btn-group">
                <Button type="button" className="btn btn-primary" onClick={(e) => this.onEditClick(rowData)}>
                    <PencilFill />
                </Button>
                {/* <Button type="button" className="btn btn-primary" onClick={() => this.onDeleteClick(rowData)}>
                    <TrashFill color="red" />
                </Button> */}
            </div>)
        }
    
        handleCloseModal = () => {
            this.setState({ show: false });
        };

    render() {

        const dateBodyTemplate = (rowData) => {
            return datetimeOrDateFromIso(rowData.reference_date);
        };

        const allowExpansion = (rowData) => {
            return rowData.movements.length > 0;
        };

        const header1 = (
            <div className="flex flex-wrap justify-content-end gap-2">
                <StockedProductModal 
                onClose={this.handleCloseModal}
                onShow={() => this.setState({show:true})} 
                onCancel={this.onCancelStockProduct} 
                show={this.state.show}
                nfrepo = {this.state.obj}
                movementsEdited={this.state.movementsEdited}
                onRemoveToEdit={this.onRemoveToEdit}
                updateMovementsEdited={this.updateMovementsEdited} />
                <Button type="Button" icon="pi pi-plus" label="Expandir Todos" onClick={() => this.expandAll(this.state.products)} text />
                <Button type="Button" icon="pi pi-minus" label="Colapsar Todos" onClick={this.collapseAll} text />
            </div>
        );

        const allowEdit = (rowData) => {
            return rowData.id;
        };

        const textEditor = (options, type='text', disabled = false) => {
            let value = options.value;
            if (type==='date'){
                value=DateTime.fromISO(value, { setZone: true }).toFormat('yyyy-MM-dd')
            }
            return <InputText disabled={disabled} type={type} value={value} onChange={(e) => options.editorCallback(e.target.value)}  />;
        };

        const textEditorKilos = (options) => {
            return textEditor(options, 'text', options.rowData.mu != 'kg' && options.rowData.mu !== '')
        };

        const textEditorLiters = (options) => {
            return textEditor(options, 'text', options.rowData.mu == 'kg' && options.rowData.mu !== '')
        };

        const rowExpansionTemplate = (data) => {
            return (
                <div className="p-3">
                    <h5><b>Ações do produto "{data.product_name}"</b></h5>
                    <Row>
                        <Form.Group as={Col} md="4" controlId="nf">
                            <Form.Label className="font-weight-bold">Data inicial</Form.Label>
                            <Form.Control required type="date" name="start_at"
                                value={DateTime.fromISO(this.state.search.start_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                                onChange={this.onChangeSearch} maxLength={200} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="nf">
                            <Form.Label className="font-weight-bold">Data final</Form.Label>
                            <Form.Control type="date" name="end_at"
                                value={DateTime.fromISO(this.state.search.end_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                                onChange={this.onChangeSearch} maxLength={200} />
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <DataTable editMode="row" onRowEditComplete={e => this.onRowEditComplete(e, e.data, data)} value={data.movements}>
                        <Column field="movement_type_description" header="Tipo" sortable></Column>
                        <Column field="reference_date" header="Data" body={dateBodyTemplate} editor={(options) => textEditor(options, 'date') } sortable></Column>
                        <Column field="quantity_kilos" header="Quilogramas" editor={(options) => textEditorKilos(options)} sortable></Column>
                        <Column field="quantity_liters" header="Litros" editor={(options) => textEditorLiters(options)} sortable></Column>
                        {/* <Column rowEditor={allowEdit} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column> */}
                        <Column body={this.alocBodyTemplate}></Column>
                        
                    </DataTable>
                </div>
            );
        };

        const header2 = (<div>
            <Row className="mb-3"></Row>
            <Row>
                <Col md="10">
                    <Row>
                        <Form.Group as={Col} md="5" controlId="search">
                            <Form.Label className="font-weight-bold" style={{ color: 'black', fontSize: '16px' }}>Situação</Form.Label>
                            <Form.Control
                                as="select" required name="search" value={this.state.mv.search}
                                onChange={this.onChange} maxLength={100} >
                                <option value='0'>Ativo</option>
                                <option value='1'>Inativo</option>
                            </Form.Control >
                            <Form.Control.Feedback>Ok</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                Campo obrigatório.
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                </Col>
                <Col>
                    {header(this.state.products, "movimimentos", this.dt, this.exportColumns)}
                </Col>
            </Row>
        </div>
        );

        return (
            <>
                <Toast ref={this.toast} />
                <Loader loading={this.state.loading} />
                <Container fluid>
                    <Row>
                        <Col md="12">
                            <Card>
                                <Card.Header>
                                    <Card.Title as="h4" className="text-center text-secondary font-weight-bold ">Retificar Ações</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form id="AddNf" noValidate validated={this.state.validated}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4" controlId="number">
                                                <Form.Label className="font-weight-bold">N° Nota fiscal</Form.Label>
                                                <Form.Control disabled type="text" name="number" placeholder="Número da Nota Fiscal" value={this.state.obj.number}
                                                    maxLength={50} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="number">
                                                <Form.Label className="font-weight-bold">Data de emissão</Form.Label>
                                                <Form.Control required type="date" name="issued_at"
                                                    value={DateTime.fromISO(this.state.obj.issued_at, { setZone: true }).toFormat('yyyy-MM-dd')}
                                                    onChange={this.onChange} maxLength={200} />
                                                <Form.Control.Feedback>Ok</Form.Control.Feedback>
                                                <Form.Control.Feedback type="invalid">
                                                    Campo obrigatório.
                                                </Form.Control.Feedback>
                                            </Form.Group>

                                        </Row>
                                        <Row>
                                            <div className="card">
                                                <Tooltip target=".export-buttons>button" position="bottom" />
                                                <DataTable ref={this.dt} value={this.state.products} header={header1}
                                                    tableStyle={{ minWidth: '50rem' }}
                                                    paginator rows={5}
                                                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                                    filterDisplay="row"
                                                    filters={this.state.filters}
                                                    emptyMessage="Não há ações"
                                                    expandedRows={this.state.expandedRows} onRowToggle={(e) => this.setExpandedRows(e.data)}
                                                    onRowExpand={(e) => this.onRowExpand(e)}
                                                    onRowCollapse={this.onRowCollapse}
                                                    rowExpansionTemplate={rowExpansionTemplate}
                                                    dataKey="id"
                                                >
                                                    <Column expander={allowExpansion} style={{ width: '5rem' }} />
                                                    {this.cols.map((col, index) => (
                                                        <Column key={index} filter sortable field={col.field} header={col.header} />
                                                    ))}
                                                </DataTable>
                                            </div>
                                        </Row>
                                    </Form>
                                </Card.Body>
                            </Card>
                            <Button disabled={this.state.movementsEdited.length == 0 && !this.state.obj.updated} 
                                className="btn btn btn-primary active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }} 
                                type="button" onClick={this.handleSubmit} >
                                Salvar
                            </Button>{' '}
                            <Button onClick={() => this.props.history.push(this.state.previousPage)} className="btn btn btn-danger active btn-floating position-sticky" style={{ bottom: '20px', right: '20px' }}>
                                Cancelar
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

AddNf.propTypes = {
    addNf: PropTypes.func.isRequired,
    updateNf: PropTypes.func.isRequired,
    getNf: PropTypes.func.isRequired,
    nfsReduc: PropTypes.object.isRequired,
    getMovements: PropTypes.func.isRequired,
    editMovements: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
    nfsReduc: state.nfsReduc,
    ufsReduc: state.ufsReduc,
    citiesReduc: state.citiesReduc,
    movementsReduc: state.movementsReduc
});

export default connect(mapStateToProps, { addNf, updateNf, getNf, getMovements, editMovements })(withRouter(AddNf));